@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');

@font-face {
    font-family: 'Helvetica';
    font-weight: 400;
    font-style: normal;
    src: url('./assets/fonts/Helvetica.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-family: 'Press Start';
    font-weight: 400;
    font-style: normal;
    src: url('./assets/fonts/PressStart.woff2') format('woff2');
    font-display: swap;
}
