.icon {
    color: #222;
    font-size: 40px;
    transition: color 0.8s ease-in-out;
}

@media only screen and (max-width: 480px) {
    .icon {
        font-size: 30px;
    }
}
