.tooltip-class {
    position: relative;
    display: inline-block;
}

.tooltip-class .tooltip-text {
    visibility: hidden;
    width: 7rem;
    height: 2.5rem;
    background-color: #212121;
    color: #fff;
    text-align: center;
    font-size: 10px;
    padding: 3px 0;
    margin-top: -5rem;
    border-radius: 6px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.tooltip-class .tooltip-text::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #212121 transparent transparent transparent;
}

.tooltip-class:hover .tooltip-text {
    visibility: visible;
}

.tooltip-class-small {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip-class-small .tooltip-text {
    visibility: hidden;
    width: 70px;
    height: 20px;
    background-color: white;
    color: #fff;
    text-align: center;
    font-size: 0.6rem;
    margin-top: -28px;
    margin-left: -8px;
    padding: 3px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

.tooltip-class-small .tooltip-text::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.tooltip-class-small:hover .tooltip-text {
    visibility: visible;
}
